import React, { useState, useEffect, useCallback, useMemo, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Card,
  CardContent,
  Alert,
  Skeleton,
  Paper,
  CircularProgress,
  Divider
} from '@mui/material';
import { 
  MapOutlined,
  SchoolOutlined,
} from '@mui/icons-material';
import { formatSchoolName, capitalizeWord } from '../utils/nameFormatter';
import { point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { apiFetch } from '../utils/api';

// Lazy load components
const SchoolMap = React.lazy(() => import('./SchoolMap'));
const K12PopulationCard = React.lazy(() => import('./K12PopulationCard'));
const K12EnrollmentCard = React.lazy(() => import('./K12EnrollmentCard'));
const DemographicsCard = React.lazy(() => import('./DemographicsCard'));
const CombinedMarketShareCard = React.lazy(() => import('./CombinedMarketShareCard'));
const NearbySchoolsComparison = React.lazy(() => import('./NearbySchoolsComparison'));

const GRADES = ['Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const DRIVE_TIMES = [5, 10, 15];

function GeographicExplorer() {
  const [schools, setSchools] = useState([]);
  const [allSchools, setAllSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [esriData, setEsriData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [esriLoading, setEsriLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolsEnrollmentData, setSchoolsEnrollmentData] = useState([]);
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [hasPreK, setHasPreK] = useState(false);
  const [enrollmentProjections, setEnrollmentProjections] = useState(null);

  const fetchSchools = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiFetch(`/schools`);
      const uniqueSchools = Array.from(new Map(data.map(school => [school.ncessch, school])).values())
        .sort((a, b) => formatSchoolName(a.name).localeCompare(formatSchoolName(b.name)));

      setSchools(uniqueSchools);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setError('Failed to load school data. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const fetchAllSchools = useCallback(async () => {
    try {
      const data = await apiFetch(`/all-schools`);
      setAllSchools(data);
    } catch (error) {
      console.error('Error fetching all schools:', error);
      setError('Failed to load all schools data. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    }
  }, [navigate]);

  const fetchMarketShareData = useCallback(async (schoolId) => {
    setEsriLoading(true);
    try {
      const data = await apiFetch(`/school/${schoolId}/esri-market-share`);
      setEsriData(data);
    } catch (error) {
      console.error('Error fetching ESRI market share data:', error);
      setError('Failed to load ESRI market share data. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    } finally {
      setEsriLoading(false);
    }
  }, [navigate]);

  const fetchEnrollmentProjections = useCallback(async (schoolId) => {
    try {
      const data = await apiFetch(`/school/${schoolId}/enrollment-projections`);
      setEnrollmentProjections(data);
    } catch (error) {
      console.error('Error fetching enrollment projections:', error);
      setError('Failed to load enrollment projections. Please try again later.');
      if (error.message === 'Session expired. Please log in again.') {
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchSchools();
    fetchAllSchools();
  }, [fetchSchools, fetchAllSchools]);

  useEffect(() => {
    if (selectedSchool) {
      fetchMarketShareData(selectedSchool.ncessch);
      fetchEnrollmentProjections(selectedSchool.ncessch);
    }
  }, [selectedSchool, fetchMarketShareData, fetchEnrollmentProjections]);

  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !esriData || !allSchools || selectedGrades.length === 0) {
      return [];
    }
  
    return allSchools.filter(school => {
      if (!school.grades_offered) {
        return false;
      }
  
      // Governance filter
      if (governanceFilter !== 'All') {
        const isCharter = school.charter === 'Yes';
        if ((governanceFilter === 'Charter' && !isCharter) || (governanceFilter === 'District' && isCharter)) {
          return false;
        }
      }
  
      // Grade filter
      const schoolGrades = school.grades_offered.split(',').map(grade => 
        grade.replace('G_', '').replace('_OFFERED', '').trim() === 'KG' ? 'Kindergarten' : grade.replace('G_', '').replace('_OFFERED', '').trim()
      );
      const hasOverlap = selectedGrades.some(grade => 
        schoolGrades.includes(grade) || 
        (grade === 'K' && schoolGrades.includes('Kindergarten')) ||
        (grade === 'Kindergarten' && schoolGrades.includes('K'))
      );
      
      if (!hasOverlap) {
        return false;
      }
  
      return true; // We'll determine the drive time in the next step
    }).map(school => {
      const schoolPoint = point([school.longitude, school.latitude]);
      let driveTime = null;
  
      for (const time of [5, 10, 15]) {
        if (esriData.drive_times && 
            esriData.drive_times[time] && 
            esriData.drive_times[time].drive_time_polygon) {
          const polygonData = esriData.drive_times[time].drive_time_polygon;
          let polygon;
  
          if (typeof polygonData === 'string') {
            try {
              const parsedPolygon = JSON.parse(polygonData);
              polygon = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: parsedPolygon.rings || parsedPolygon
                }
              };
            } catch (error) {
              console.error(`Error parsing polygon data for ${time} minutes:`, error);
              continue;
            }
          } else if (typeof polygonData === 'object' && polygonData.rings) {
            polygon = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: polygonData.rings
              }
            };
          } else {
            console.warn(`Unexpected polygon data format for ${time} minutes:`, polygonData);
            continue;
          }
  
          if (booleanPointInPolygon(schoolPoint, polygon)) {
            driveTime = time;
            break;
          }
        }
      }
  
      return {
        ...school,
        drive_time: driveTime 
      };
    }).filter(school => school.drive_time !== null || school.ncessch === selectedSchool.ncessch);
  }, [selectedSchool, allSchools, esriData, governanceFilter, selectedGrades]);

  useEffect(() => {
    if (selectedSchool && filteredSchools.length > 0 && esriData) {
      const fetchSchoolsEnrollmentData = async () => {
        try {
          const ncesschList = filteredSchools.map(school => school.ncessch);
          const data = await apiFetch('/schools-enrollment-by-grade', {
            method: 'POST',
            body: JSON.stringify({ ncesschList }),
          });
          setSchoolsEnrollmentData(data);
        } catch (error) {
          console.error('Error fetching schools enrollment data:', error);
          setError('Failed to load enrollment data. Please try again later.');
        } finally {
        }
      };
      fetchSchoolsEnrollmentData();
    }
  }, [selectedSchool, filteredSchools, esriData]);

  useEffect(() => {
    if (selectedSchool && selectedSchool.grades) {
      const schoolGrades = selectedSchool.grades.map(grade => 
        grade.replace('Grade ', '').trim() === '0' ? 'Kindergarten' : grade.replace('Grade ', '').trim()
      ).filter(grade => grade !== 'Pre-Kindergarten' && grade !== 'PK');
      
      const schoolHasPreK = selectedSchool.grade_range.toLowerCase().includes('pk') || 
                            selectedSchool.grade_range.toLowerCase().includes('pre-k') ||
                            selectedSchool.grade_range.toLowerCase().includes('pre-kindergarten');
      setHasPreK(schoolHasPreK);
      setSelectedGrades(schoolGrades);
    } else {
      setSelectedGrades([]);
      setHasPreK(false);
    }
  }, [selectedSchool]);

  const handleGradeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const gradeToAge = useCallback((grade) => {
    if (grade === 'Kindergarten') return 5;
    return parseInt(grade) + 5;
  }, []);

  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      setSelectedSchool({...school, name: formatSchoolName(school.name)});
      setEsriData(null);
    }
  };

  const handleGovernanceChange = (event) => {
    setGovernanceFilter(event.target.value);
  };

  const formatAddress = (school) => {
    const formattedStreetAddress = school.street_address
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    const formattedCity = school.city
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    return `${formattedStreetAddress}\n${formattedCity}, ${school.state} ${school.zip_code}`;
  };

  // Render loading skeleton
  const renderSkeleton = () => (
    <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
      {[1, 2, 3].map((item) => (
        <Grid item xs={12} md={4} key={item}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      ))}
    </Grid>
  );

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          Geographic Explorer
        </Typography>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 4 }} />
        {renderSkeleton()}
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
        Geographic Explorer
      </Typography>
      
      {!selectedSchool ? (
        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
          Use this tool to explore the geographic context of schools in your area. Start by selecting a school.
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
          Refine your analysis using the <strong>Governance</strong>, <strong>Grades</strong>, and <strong>Drive Time</strong> filters. 
          Click on any headline card to view more detailed information and insights about the selected metrics.
        </Typography>
      )}
      
      {!selectedSchool ? (
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel id="initial-school-select-label">Select a School</InputLabel>
          <Select
            labelId="initial-school-select-label"
            id="initial-school-select"
            value=""
            label="Select a School"
            onChange={handleSchoolChange}
          >
            {schools.map((school) => (
              <MenuItem key={school.ncessch} value={school.ncessch}>
                {formatSchoolName(school.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={2} alignItems="flex-start">
            {/* School information */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <SchoolOutlined sx={{ fontSize: 24, color: 'primary.main', mr: 1 }} />
                <Typography variant="h6">
                  {formatSchoolName(selectedSchool.name)}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {formatAddress(selectedSchool)}
              </Typography>
            </Grid>
  
            {/* Vertical Divider */}
            <Grid item xs={12} md="auto">
              <Divider orientation="vertical" flexItem />
            </Grid>
  
            {/* Filters */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Filters
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="school-select-label">School</InputLabel>
                    <Select
                      labelId="school-select-label"
                      id="school-select"
                      value={selectedSchool.ncessch}
                      label="School"
                      onChange={handleSchoolChange}
                    >
                      {schools.map((school) => (
                        <MenuItem key={school.ncessch} value={school.ncessch}>
                          {formatSchoolName(school.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="governance-select-label">Governance</InputLabel>
                    <Select
                      labelId="governance-select-label"
                      id="governance-select"
                      value={governanceFilter}
                      label="Governance"
                      onChange={handleGovernanceChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Charter">Charter</MenuItem>
                      <MenuItem value="District">District</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="grade-select-label">Grades</InputLabel>
                    <Select
                      labelId="grade-select-label"
                      id="grade-select"
                      multiple
                      value={selectedGrades}
                      onChange={handleGradeChange}
                      input={<OutlinedInput label="Grades" />}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {GRADES.map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          <Checkbox checked={selectedGrades.indexOf(grade) > -1} />
                          <ListItemText primary={grade} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="drive-time-select-label">Drive Time</InputLabel>
                    <Select
                      labelId="drive-time-select-label"
                      id="drive-time-select"
                      value={selectedDriveTime}
                      label="Drive Time"
                      onChange={(event) => setSelectedDriveTime(event.target.value)}
                    >
                      {DRIVE_TIMES.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time} minutes
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
  
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      ) : esriLoading ? (
        renderSkeleton()
      ) : selectedSchool && esriData ? (
        <>
          <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
            <Grid item xs={12} md={3}>
              <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                <CombinedMarketShareCard
                  selectedSchool={selectedSchool}
                  filteredSchools={filteredSchools}
                  esriData={esriData}
                  selectedDriveTime={selectedDriveTime}
                  schoolsEnrollmentData={schoolsEnrollmentData}
                  governanceFilter={governanceFilter}
                  selectedGrades={selectedGrades}
                  enrollmentProjections={enrollmentProjections}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} md={3}>
              <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                <K12PopulationCard
                  esriData={esriData}
                  selectedSchool={selectedSchool}
                  selectedDriveTime={selectedDriveTime}
                  selectedGrades={selectedGrades}
                  gradeToAge={gradeToAge}
                  hasPreK={hasPreK} 
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} md={3}>
              <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                <K12EnrollmentCard
                  selectedSchool={selectedSchool}
                  filteredSchools={filteredSchools}
                  enrollmentData={schoolsEnrollmentData}
                  selectedDriveTime={selectedDriveTime}
                  governanceFilter={governanceFilter}
                  selectedGrades={selectedGrades}
                  hasPreK={hasPreK} 
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} md={3}>
              <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                <DemographicsCard
                  esriData={esriData}
                  selectedDriveTime={selectedDriveTime}
                />
              </Suspense>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <MapOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                        School Location and Service Area
                      </Typography>
                    </Box>
                    <Box sx={{ height: 500, position: 'relative' }}>
                      <SchoolMap 
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools}
                        esriData={esriData}
                        visibleDriveTimes={[selectedDriveTime]}
                        schoolsEnrollmentData={schoolsEnrollmentData}
                        governanceFilter={governanceFilter}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Suspense>
            </Grid>
          </Grid>
          <Suspense fallback={<Skeleton variant="rectangular" height={300} />}>
            <NearbySchoolsComparison
              filteredSchools={filteredSchools}
              selectedSchool={selectedSchool}
              visibleDriveTimes={[selectedDriveTime]}
              schoolsEnrollmentData={schoolsEnrollmentData}
              governanceFilter={governanceFilter}
            />
          </Suspense>
        </>
      ) : null}
    </Container>
  );
  }
  
  export default GeographicExplorer;