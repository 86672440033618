import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  CircularProgress,
  Link as MuiLink
} from '@mui/material';
import customLogger from '../utils/customLogger';
import getFirebaseErrorMessage from '../utils/firebaseErrorMessages';
import { useAuth } from '../contexts/AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setError('');
    setIsLoading(true);
    try {
      customLogger(`Login attempt initiated for email: ${email}`, 'info');
      const { access_token } = await login(email, password);
      customLogger(`Received access token: ${access_token.substring(0, 10)}...`, 'info');
      localStorage.setItem('access_token', access_token);
      customLogger(`Access token stored in localStorage`, 'info');
      customLogger(`User logged in successfully: ${email}`, 'info');
      navigate('/dashboard');
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code) || error.message;
      customLogger(`Login failed for ${email}: ${errorMessage}`, 'error');
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box
            component="img"
            sx={{
              height: 250,
              mb: 2,
              animation: 'fadeIn 0.5s ease-in-out',
              '@keyframes fadeIn': {
                '0%': { opacity: 0, transform: 'translateY(-20px)' },
                '100%': { opacity: 1, transform: 'translateY(0)' },
              },
              cursor: 'pointer', // Add cursor style to indicate clickability
            }}
            alt="EDC Logo"
            src="/images/edc_logo.png"
          />
        </Link>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                '& fieldset': { borderColor: '#14b8ab' },
                '&:hover fieldset': { borderColor: '#14b8ab' },
                '&.Mui-focused fieldset': { borderColor: '#14b8ab' },
              },
              '& .MuiInputLabel-root': { color: '#2a2e30' },
              '& .MuiInputBase-input': { color: '#2a2e30' },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ 
              '& .MuiOutlinedInput-root': { 
                '& fieldset': { borderColor: '#14b8ab' },
                '&:hover fieldset': { borderColor: '#14b8ab' },
                '&.Mui-focused fieldset': { borderColor: '#14b8ab' },
              },
              '& .MuiInputLabel-root': { color: '#2a2e30' },
              '& .MuiInputBase-input': { color: '#2a2e30' },
            }}
          />
          <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
            By clicking "Login", you agree to our{' '}
            <MuiLink component={Link} to="/terms-of-service" target="_blank" rel="noopener">
              Terms of Service
            </MuiLink>
            .
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 3, 
              mb: 2, 
              backgroundColor: '#4338ca',
              '&:hover': {
                backgroundColor: '#3730a3',
              },
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
          {error && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Link to="/reset-password" style={{ textDecoration: 'none' }}>
              <Typography color="#14b8ab">
                Forgot Password?
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;