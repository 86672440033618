import React from 'react';
import { Typography, Box, Paper, Grid, Card, CardContent } from '@mui/material';
import { MapOutlined, AutoGraphOutlined, MapsHomeWork, DescriptionOutlined } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const featureCards = [
  {
    title: 'Network Map',
    description: 'View all schools within the network with enrollment and grade-range information. Filter by city and state.',
    icon: <MapOutlined fontSize="large" />,
    link: '/map'
  },
  {
    title: 'Projected Enrollment',
    description: 'Explore historical and projected enrollment data for the next five years. View by grade level and add custom data.',
    icon: <AutoGraphOutlined fontSize="large" />,
    link: '/school-dashboard'
  },
  {
    title: 'Geographic Explorer',
    description: 'Understand local context with population and enrollment trends. View nearby schools within various drive times.',
    icon: <MapsHomeWork fontSize="large" />,
    link: '/geographic-explorer'
  },
  {
    title: 'Data Reports',
    description: 'Download detailed reports, including ESRI demographic data for selected schools.',
    icon: <DescriptionOutlined fontSize="large" />,
    link: '/data-reports'
  }
];

const Home = ({ user }) => {
  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome {user ? user.email.split('@')[0] : 'Guest'}!
          </Typography>
          <Typography variant="body1">
            Explore the following features to get started.
          </Typography>
        </Box>
      </Paper>

      <Grid container spacing={4}>
        {featureCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card 
              component={RouterLink} 
              to={card.link} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                  transition: 'all 0.3s ease-in-out'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  {card.icon}
                </Box>
                <Typography gutterBottom variant="h5" component="h2" align="center">
                  {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Home;