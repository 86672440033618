import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Box 
} from '@mui/material';
import { Link } from 'react-router-dom';
import SiteFooter from './SiteFooter'; // Import the new SiteFooter component

function AboutUs() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Navigation Bar */}
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img src="/images/edc_logo_circle.png" alt="EDC Logo" style={{ height: '40px' }} />
            </Link>
          </Box>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/login">Login</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h2" gutterBottom align="center">
          About Education Data Collective
        </Typography>
        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          Empowering educational institutions with data-driven insights
        </Typography>
        <Typography variant="body1" paragraph>
         Education Data Collective's mission is to support education organization's strategic planning processes through providing access to a robust set of information that is interactive, connected, and relevant.
        </Typography>
      </Container>

      {/* Team Section */}
      <Box sx={{ bgcolor: 'grey.100', py: 8, flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom>
            Our Team
          </Typography>
          <Grid container spacing={4}>
            {/* Brian Eschbacher Card */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="600"
                  image="/images/eschbacher_headshot.jpg"
                  alt="Brian Eschbacher - Co-Founder & CEO of Education Data Collective"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Brian Eschbacher
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Co-Founder & CEO
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Brian's journey in education began while volunteering in Chicago schools after years in private sector management consulting. In 2011, he joined Denver Public Schools (DPS) as part of the Broad Residency, where he served as Executive Director of Planning & Enrollment for seven years.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    At DPS, Brian supported key strategic initiatives including the 2012 and 2016 Bonds, and the SchoolChoice unified enrollment system. Since founding Eschbacher Consulting in 2018, he has continued to work at the intersection of demography and school choice, supporting enrollment planning across 27 cities for various educational institutions and organizations.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Dr. Raja Ridgway Card */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="600"
                  image="/images/ridgway_headshot.jpg"
                  alt="Dr. Raja Ridgway - Co-Founder & CTO of Education Data Collective"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Dr. Raja Ridgway
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Co-Founder & CTO
                  </Typography>
                  <Typography variant="body2" paragraph>
                   Raja started his career as a teacher and now works at the intersection of education, data, and design. Raja takes a person-centered approach to his work, recognizing that the best processes and products are those that are built together. 
                  </Typography>
                  <Typography variant="body2" paragraph>
                  Raja directed data and analytics at KIPP Colorado and has developed data products for organizations (e.g., Teach for America, Boys & Girls Club) and school districts (e.g. Roaring Fork School District). He combines education experience with expertise in web development and data science to create data-driven solutions for schools. At Education Data Collective, Raja builds tools to empower educational organizations nationwide.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <SiteFooter />
    </Box>
  );
}

export default AboutUs;